@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/Segoe_UI.ttf');
}

body {
    font-family: 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}

a {
    text-decoration: none !important;
}

.iamge-slider {
    height: 100%;
}

.pg-viewer-wrapper {
    overflow: auto !important;
    background-color: white;
}

::-webkit-scrollbar {
    width: 8px;
    height: 0px;
    background: #f8f8f8;
}

::-webkit-scrollbar-thumb {
    width: 8px;
    height: 0px;
    background: #9c9a9a;
    border-radius: 5px;
}

.pdf-viewer {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}


@keyframes loadingA {
    0% {
        height: 15px;
    }

    50% {
        height: 35px;
    }

    100% {
        height: 15px;
    }
}

.grid-row:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

/* .grid-row:last-child .cell:first-child {
    border-bottom-left-radius: 8px;
} */

.grid-row:last-child .first-cell:first-child {
    border-bottom-left-radius: 8px;
}

.grid-row:last-child .last {
    border-bottom-right-radius: 8px;
}

#react-doc-viewer {
    height: 100%
}

.multiple-select {
    background-color: #98ceff47 !important;
}

[data-rmiz] {
    height: 100%;
}

[data-rmiz-content] {
    height: 100%;
}

@keyframes downarrow {
    0% {
        -webkit-transform: translateY(0);
        opacity: 0.4
    }

    100% {
        -webkit-transform: translateY(0.4em);
        opacity: 0.9
    }
}